import React from 'react';
import { Col } from 'reactstrap';

function Signatures() {
    return (
        <Col>
            <p>Respectfully,</p>

            <ul className="list-unstyled">
                <li>ROBERT ADLER, MD, MS.Ed., Chief Medical Officer, CHLA Health System, Senior Advisor to the Chair of Pediatrics</li>
                <li>LISA AZIZ-ZADEH, Associate Professor of Psychology and Occupational Science and Occupational Therapy</li>
                <li>ITZHAK BARS, Professor of Physics and Astronomy</li>
                <li>EYAL BEN-ISAAC, MD, Associate Professor of Clinical Pediatrics, Keck School of Medicine and  CHLA</li>
                <li>YEHUDA BEN-ZION, Professor of Earth Sciences, Director of Southern California Earthquake Center</li>
                <li>BERENICE A. BENAYOUN, Assistant Professor of Gerontology, Biological Sciences, Biochemistry and Molecular Medicine</li>
                <li>JACK M. BERGER, MS, MD, Ph.D, Professor Emeritus of Clinical Anesthesiology</li>
                <li>LISA M. BITEL, Dean's Professor of Religion and Professor of History</li>
                <li>RICHARD L. BRUTCHEY, Professor of Chemistry</li>
                <li>IRENE CHIOLO, Gabilan Associate Professor of Biological Sciences</li>
                <li>URI ELKAYAM, MD, Professor of Medicine and Obstetrics and Gynecology, Director of Maternal Cardiology</li>
                <li>SUSAN R. ESTRICH, Robert Kingsley Professor of Law and Political Science</li>
                <li>JAHAN M. DAWLATY, Associate Professor of Chemistry</li>
                <li>DION DICKMAN, Associate Professor of Biological Sciences</li>
                <li>ROSA DI FELICE, Associate Professor of Physics and Astronomy and Quantitative and Computational Biology</li>
                <li>DONALD FEINSTEIN, MD, MACP, Professor of Medicine Emeritus</li>
                <li>HENRYK FLASHNER, Professor, Department of Aerospace and Mechanical Engineering</li>
                <li>ERIC M. FRIEDLANDER, Dean’s Professor and Chair of Mathematics</li>
                <li>SUSAN FRIEDLANDER, Professor of Mathematics and Director of the Center for Applied Mathematical Sciences</li>
                <li>PHILIPPE FRIEDLICH, Professor of Pediatrics & Surgery, USC Keck School of Medicine</li>
                <li>LEANA GOLUBCHIK, Stephen and Etta Varra Professor of Computer Science and Electrical and Computer Engineering</li>
                <li>MYRON F. GOODMAN, Professor of Biological Sciences and Chemistry</li>
                <li>MIKE GRUNTMAN, Professor of Astronautics</li>
                <li>LARRY HARRIS, Fred V. Keenan Chair in Finance, Marshall School of Business</li>
                <li>JEFFREY B. HIRSCH, Adjunct Professor, Annenberg School for Communications & Journalism</li>
                <li>JUDITH A. HIRSCH, Gabilan Distinguished Professor in Science and Engineering and Professor of Biological Sciences</li>
                <li>ELLIS HOROWITZ, Professor of Computer Science & Electrical Engineering</li>
                <li>VITALY V. KRESIN,  Professor of Physics and Astronomy</li>
                <li>ANNA I. KRYLOV, Professor of Chemistry, Member, International Academy of Quantum Molecular Science, Foreign Member, Academia Europaea</li>
                <li>CLIVE LENNOX, USC Accounting Associates Professor of Accounting, Marshall School of Business, Leventhal School of Accounting</li>
                <li>PAT LEVITT, PhD, Chief Scientific Officer, Vice President and Director, The Saban Research Institute, Simms/Mann Chair in Developmental Neurogenetics, Children’s Hospital Los Angeles, WM Keck Provost Professor of Neurogenetic, Keck School of Medicine</li>
                <li>DANIEL LIDAR, Viterbi Professor of Engineering and Professor of Electrical Engineering, Chemistry, and Physics</li>
                <li>EMILY R. LIMAN, Professor of Neurobiology</li>
                <li>RICHARD LUBMAN, MD, Associate Professor of Medicine</li>
                <li>CHI MAK, Professor of Chemistry</li>
                <li>GERARD MEDIONI, Professor Emeritus of Computer Science</li>
                <li>JERRY MENDEL, Emeritus Professor of Electrical Engineering</li>
                <li>M. SUSAN MONTGOMERY, Gabilan Distinguished Professor in Science and Engineering and Professor of Mathematics</li>
                <li>ALAN L. NAGER, Director, Emergency & Transport Medicine, Children's Hospital Los Angeles, Professor of Pediatrics</li>
                <li>SHRI NARAYANAN, University Professor, Niki and Max Nikias Chair in Engineering and Professor of Electrical and Computer Engineering, Computer Science, Linguistics, Psychology, Pediatrics, and Otolaryngology</li>
                <li>JANET OLDAK, Professor of Dentistry, Herman Ostrow  School of Dentistry</li>
                <li>DAPHNA OYSERMAN, Dean’s Professor of Psychology</li>
                <li>RICHARD S. PANUSH, MD, Master, American College of Physicians, Master, American College of Rheumatology, Professor of Medicine</li>
                <li>DANIEL POLLACK, Professor of Keyboard Studies, Thornton School of Music</li>
                <li>STEFAN POLLACK, Adjunct Professor, Annenberg School of Communications</li>
                <li>G. K. SURYA PRAKASH, Professor and George and Judith Olah Nobel Laureate Chair in Hydrocarbon Chemistry</li>
                <li>HANNA REISLER, Lloyd Armstrong Jr. Chair in Science and Engineering and Professor of Chemistry, Member, American Academy of Arts and Sciences</li>
                <li>STEVEN RICHEIMER, MD, Chief, Division of Pain Medicine, Professor of Anesthesiology & Psychiatry</li>
                <li>REMO ROHS, Professor and Department Chair, Quantitative and Computational Biology</li>
                <li>ILAN ROTSTEIN, Professor, Herman Ostrow School of Dentistry</li>
                <li>LUCILLE ROTSTEIN, Clinical Associate Professor, Herman Ostrow School of Dentistry</li>
                <li>DAN SCHNUR, Adjunct Professor, Annenberg School of Communications</li>
                <li>NORBERT SCHWARZ, Provost Professor,  Department of Psychology & Marshall School of Business, Co-director, USC Dornsife Mind & Society Center</li>
                <li>HAROLD SLAVKIN, Professor and Dean Emeritus, Herman Ostrow School of Dentistry; Member, National Academy of Medicine; Member, International and American College of Dentistry; previous Director, National Institute of Dental Research, NIH  (1995-2000)</li>
                <li>DOUGLAS C. SOLOW, DDS, MBA, G. Donald Montgomery Professor of Dentistry, Associate Dean of Clinical Affairs, Director, Herman Ostrow School of Dentistry Faculty Practice</li>
                <li>WILLIAM STOHL, MD, PhD, Master, American College of Rheumatology, Professor of Medicine</li>
                <li>MARK E. THOMPSON, Ray R. Irani Chair of Chemistry, Fellow of the National Academy of Engineering</li>
                <li>ANDREW VITERBI, Presidential Chair Professor of Electrical Engineering, Member, National Academy of Engineering,  Member, National Academy of Sciences</li>
                <li>CHERYL WAKSLAK, Associate Professor of Management and Organization & McAlister Associate Professor in Business Administration</li>
                <li>ARIEH WARSHEL, Nobel Laureate, Dana and David Dornsife Chair in Chemistry, Member, National Academy of Sciences</li>
                <li>MICHAEL WATERMAN, University Professor Emeritus, Member, National Academy of Sciences, Member, National Academy of Engineering</li>
                <li>MARK WEINSTEIN, Associate Professor of Finance and Business Economics Emeritus</li>
                <li>RUTH WEISBERG, Professor, Roski School of Art and Design</li>
                <li>REGINA WITTENBERG MOERMAN, Accounting Circle Professor of Business Administration</li>
                <li>CURT WITTIG, Paul A. Miller Professor of Letters, Arts and Sciences</li>
                <li>VLADIMIR ZELMAN, MD, PhD, Clinical Professor of Anesthesiology, Foreign Member, Russian Academy of Science</li>
            </ul>
        </Col>
    );
}

export default Signatures;