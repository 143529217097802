import React from 'react';
import { Col } from 'reactstrap';
// import './references.scss';

function References() {
  return (
    <Col>
      <div className="references-wrapper">
        <h2>References</h2>
        <ol>
          <li id="link1"> {/*#1*/}
            CanaryMission profile of Yasmeen Mashayekh: &nbsp;
            <a href="https://canarymission.org/individual/Yasmeen_Mashayekh"
            target="_blank" rel="noopener noreferrer">
              https://canarymission.org/individual/Yasmeen_Mashayekh
            </a>
          </li>
          <li id="link2"> {/*#2*/}
            <a href="https://www.stopantisemitism.org/antisemite-of-the-week-6/antisemite-of-the-week-yasmeen-mashayekh-thirst-to-kill"
               target="_blank" rel="noopener noreferrer">
              https://www.stopantisemitism.org/antisemite-of-the-week-6/antisemite-of-the-week-yasmeen-mashayekh-thirst-to-kill
            </a>
          </li>
          <li id="link3"> {/*#3*/}
            USC Open Letter about Rose Ritch's case (2020): &nbsp;
            <a href="https://usc-faaz.org"
            target="_blank" rel="noopener noreferrer">
              https://usc-faaz.org
            </a>
          </li>
          <li id="link4"> {/*#4*/}
            USC Open Letter about an inflammatory and misleading online "Statement" endorsed by USC’s Department of Gender and Sexuality Studies (2021): &nbsp;
            <a href="https://usc-faaz-2021.org"
               target="_blank" rel="noopener noreferrer">
              https://usc-faaz-2021.org
            </a>
          </li>
        </ol>
      </div>
    </Col>
  );
}

export default References;