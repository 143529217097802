import React from 'react';
import {Col} from 'reactstrap';

function Reactions() {
  return (
    <Col>
      <div className="reactions-wrapper">
        <h2>Reactions</h2>
        <ul>
          <li>
            <a href="FacultyLetter_OCR_080122.pdf">Letter from President Folt</a> (August 1, 2022).
          </li>
          <li>
            The Jewish News Syndicate
            article: <a
            href="https://www.jns.org/federal-probe-investigates-usc-for-anti-semitic-environment-against-campus-jewish-leader/"
            target="_blank"
            rel="noopener noreferrer">Federal probe investigates USC for anti-Semitic environment against campus Jewish leader</a> (July 27, 2022)
          </li>
          <li>
            The Inside Higher Ed
            article: <a
            href="https://www.insidehighered.com/news/2022/07/27/ed-launches-title-vi-investigation-usc?utm_source=Inside+Higher+Ed&utm_campaign=72098ee621-DNU_2021_COPY_02&utm_medium=email&utm_term=0_1fcbc04421-72098ee621-237003801&mc_cid=72098ee621&mc_eid=fc60cbb888"
            target="_blank"
            rel="noopener noreferrer">USC Faces Investigation Over Alleged Student Harassment</a> (July 27, 2022)
          </li>
          <li>
            The Jewish Journal
            article: <a
            href="https://jewishjournal.com/news/350269/education-dept-to-investigate-uscs-handling-of-antisemitic-harassment-against-rose-ritch/"
            target="_blank"
            rel="noopener noreferrer">Handling of Antisemitic Harassment Against Rose Ritch</a> (July 26, 2022)
          </li>
          <li>
            The Algemeiner
            article: <a
            href="https://www.algemeiner.com/2022/07/26/university-of-southern-california-faces-federal-probe-over-harassment-of-pro-israel-student-leader/"
            target="_blank"
            rel="noopener noreferrer">University of Southern California Faces Federal Probe Over Harassment of Pro-Israel Student Leader</a> (July 26, 2022)
          </li>
          <li>
            The Jewish Insider
            article: <a
            href="https://jewishinsider.com/2022/07/department-of-education-to-open-investigation-into-usc-over-antisemitism-allegations/"
            target="_blank"
            rel="noopener noreferrer">Department of Education to open investigation into USC over antisemitism allegations</a> (July 26, 2022)
          </li>
          <li>
            The Times of Israel
            article: <a
            href="https://www.timesofisrael.com/federal-probe-opened-into-alleged-antisemitism-at-university-of-southern-california/"
            target="_blank"
            rel="noopener noreferrer">Federal probe opened into alleged antisemitism at University of Southern California</a> (July 26, 2022)
          </li>
          <li>
            <a href="https://twitter.com/yudapearl/status/1503967475519557634"
               target="_blank" rel="noopener noreferrer">Judea Pearl's tweet</a> (tweeted on March 16, 2022)
          </li>
          <li>
            The Heterodox STEM
            article: <a
            href="https://hxstem.substack.com/p/on-cancel-culture-and-antisemitism-3df"
            target="_blank"
            rel="noopener noreferrer">On cancel culture and antisemitism in academia: part II</a> (February 25, 2022)
          </li>
          <li>
            The Jerusalem Post
            article: <a
            href="https://www.jpost.com/diaspora/antisemitism/article-696897"
            target="_blank"
            rel="noopener noreferrer">End harassment of pro-Israel students - 1,500 students petition USC</a> (February 18, 2022)
          </li>
          <li>
            The Cleveland Jewish News
            article: <a
            href="https://www.clevelandjewishnews.com/jns/usc-asked-to-expand-groups-protected-against-harassment-to-include-zionist-students/article_f71fd90f-e6e2-58d5-b640-fd90fad77026.html"
            target="_blank"
            rel="noopener noreferrer">USC asked to expand groups protected against harassment to include Zionist students</a> (February 18, 2022)
          </li>
          <li>
            The Jewish Press
            article: <a
            href="https://www.jewishpress.com/news/us-news/1444-petitioners-to-usc-fix-double-standard-in-treating-anti-zionist-harassment/2022/02/18/"
            target="_blank"
            rel="noopener noreferrer">1,444 Petitioners to USC: Fix Double Standard in Treating Anti-Zionist Harassment</a> (February 18, 2022)
          </li>

          <li>
            The San Diego Jewish World
            article: <a
            href="https://www.sdjewishworld.com/2022/02/17/more-than-1000-petitioners-to-usc-fix-double-standard-on-anti-zionist-harassment/"
            target="_blank"
            rel="noopener noreferrer">More Than 1,000 Petitioners to USC: Fix Double Standard on Anti-Zionist Harassment</a> (February 17, 2022)
          </li>
          <li>
            The Algemeiner
            article: <a
            href="https://www.algemeiner.com/2022/02/15/university-lecture-by-israeli-chemist-latest-target-of-antisemitic-zoombombing-at-campus-events/"
            target="_blank"
            rel="noopener noreferrer">University Lecture by Israeli Chemist Latest Target of Antisemitic ‘Zoombombing’ at Campus Events</a> (February 15, 2022)
          </li>
          <li>
            The Daily Trojan
            article: <a
            href="https://dailytrojan.com/2022/01/25/tweets-letters-stir-anger/"
            target="_blank"
            rel="noopener noreferrer">"Tweets, letters stir anger"</a> (January 25, 2022)
          </li>
          <li>
            Judea's Pearl speech <a
            href="https://www.youtube.com/watch?v=2WF2DF6xmV8&t=1s"
            target="_blank"
            rel="noopener noreferrer">"Engaging Zionist Faculty on Campus"</a> given at Stand by Us
            International Conference (January 24, 2022)
          </li>
          <li>
            The Jewish Journal
            article: <a
            href="https://jewishjournal.com/commentary/opinion/344333/campus-double-standards-and-the-need-to-protect-jewish-and-zionist-students/"
            target="_blank"
            rel="noopener noreferrer">"Campus Double Standards and the Need to Protect Jewish and Zionist Students"</a> (January 21, 2022)
          </li>
          <li>
            <a href="https://academicengagement.org/wp-content/uploads/2022/01/2022-01-18-AEN-Statement-on-USC-Action-Plan-to-Combat-Antisemitism.pdf"
            target="_blank"
            rel="noopener noreferrer">Academic Engagement Network Statement</a> (January 18, 2022)
          </li>
          <li>
            The Jewish Journal
            article: <a
            href="https://jewishjournal.com/los_angeles/344103/usc-president-announces-measures-to-combat-antisemitism-on-campus/"
            target="_blank"
            rel="noopener noreferrer">"USC President Announces Measures to Combat Antisemitism on
            Campus"</a> (January 14, 2022)
          </li>
          <li>
            <a href="https://twitter.com/yudapearl/status/1481853270842691587"
               target="_blank" rel="noopener noreferrer">Judea Pearl's tweet</a> (tweeted on January 13, 2022)
          </li>
          <li>
            <a href="https://we-are.usc.edu/wp-content/uploads/2022/01/Jewish_community_1.13.22.pdf"
               target="_blank" rel="noopener noreferrer">A letter from President Folt to the
              USC community regarding university efforts to combat antisemitism</a> (January 13, 2022)
          </li>
          <li>
            <a href="https://twitter.com/yudapearl/status/1477014077650063366"
               target="_blank" rel="noopener noreferrer">Judea Pearl's end-of-the-year
              tweet</a> (tweeted on December 31, 2021)
          </li>
          <li>
            The Jewish News Syndicate
            article: <a
            href="https://www.jns.org/usc-administrations-response-to-anti-semitism-comes-under-the-microscope/"
            target="_blank"
            rel="noopener noreferrer">"USC administration’s response to anti-Semitism comes under the microscope"</a> (December 30, 2021)
          </li>
          <li>
            <a
              href="https://boardoftrustees.usc.edu/statement-from-usc-board-chair-on-university-inclusion-in-recent-anti-semitism-top-ten-list-dec-28-2021/"
              target="_blank"
              rel="noopener noreferrer">Statement from USC Board of Trustees Chair Rick J. Caruso on university’s
              inclusion in 2021 anti-Semitism top ten list </a> (December 28, 2021)
          </li>
          <li>
            <a
            href="https://we-are.usc.edu/2021/12/28/universitys-response-to-simon-wiesenthal-center/"
            target="_blank"
            rel="noopener noreferrer">USC response to Simon Wiesenthal Center</a> (December 28, 2021)
          </li>
          <li>
            The Jewish News Syndicate
            article: <a
            href="https://www.jns.org/opinion/the-ascent-of-the-crybully-in-campus-anti-israel-activism/"
            target="_blank"
            rel="noopener noreferrer">"The ascent of the crybully in campus anti-Israel activism"</a> (December 27, 2021)
          </li>
          <li>
            USC case and the Open Letter mentioned in the <a
            href="http://support.wiesenthal.com/site/R?i=TvxDyOiZfV0oXSstOHpgShU2xFHRQhkqa5P4fbeZRMQEbfc5mNkf-g"
            target="_blank"
            rel="noopener noreferrer">Global Anti-semitism Top Ten of 2021 List</a> by Simon
            Wiesenthal Center
          </li>
          <li>
            <a href="https://twitter.com/yudapearl/status/1475372756673851392"
               target="_blank" rel="noopener noreferrer">Judea Pearl calls our
              Open Letter the 11th most inspiring moment in the fight against
              antisemitism in 2021</a> (tweeted on December 26, 2021)
          </li>
          <li>
            Elder of Ziyon post: <a
            href="https://elderofziyon.blogspot.com/2021/12/what-media-is-missing-in-their-reports.html?m=1"
            target="_blank" rel="noopener noreferrer">"What The Media Is Missing In Their Reports
            On Campus Antisemitism (Daled Amos)"</a> (December 21, 2021)
          </li>
          <li>
            Two letters to the editor (scroll down to find them) talk about the USC case: <a
            href="https://nypost.com/2021/12/22/letters-to-the-editor-dec-23-2021/"
            target="_blank"
            rel="noopener noreferrer">"Letters to the Editor — Dec. 23, 2021"</a> (December 23, 2021)
          </li>
          <li>
            The California Political Review
            article: <a
            href="http://www.capoliticalreview.com/trending/usc-student-diversity-senator-under-fire-for-tweet-threatening-to-kill-zionists/"
            target="_blank"
            rel="noopener noreferrer">"USC Student ‘Diversity’ Senator Under Fire for Tweet Threatening to
            Kill Zionists"</a> (December 20, 2021)
          </li>
          <li>
            The New York Post
            article: <a
            href="https://nypost.com/2021/12/18/usc-student-diversity-senator-facing-backlash-for-anti-israel-tweets/"
            target="_blank"
            rel="noopener noreferrer">"USC student ‘diversity’ senator under fire for tweet threatening to
            kill Zionists"</a> (December 18, 2021)
          </li>
          <li>
            The Fox News
            article: <a
            href="https://www.foxnews.com/us/california-inclusion-student-senator-blasted-for-kill-zionists-tweet-high-level-of-hypocrisy"
            target="_blank"
            rel="noopener noreferrer">"California inclusion student senator blasted for 'kill' zionists
            tweet: 'High level of hypocrisy'"</a> (December 18, 2021)
          </li>
          <li>
            The Texas News Today
            article: <a
            href="https://texasnewstoday.com/california-inclusion-student-senator-blasts-for-killing-zionist-tweets-high-level-hypocrisy/578052/"
            target="_blank"
            rel="noopener noreferrer">"California Inclusion Student Senator Blasts for “Killing” Zionist
            Tweets: “High Level Hypocrisy”"</a> (December 18, 2021)
          </li>
          <li>
            The Bharat Express News
            article: <a
            href="https://www.thebharatexpressnews.com/california-student-senator-for-inclusion-slammed-for-killing-zionists-tweet-high-level-of-hypocrisy/"
            target="_blank"
            rel="noopener noreferrer">"California student senator for inclusion slammed for “killing”
            Zionists tweet: “High level of hypocrisy”"</a> (December 18, 2021)
          </li>
          <li>
            The BizPac Review
            article: <a
            href="https://www.bizpacreview.com/2021/12/18/university-hasnt-disciplined-diversity-and-inclusion-ambassador-for-horribly-anti-semitic-tweets-1177603/"
            target="_blank"
            rel="noopener noreferrer">"University hasn’t disciplined ‘diversity and inclusion’ ambassador
            for horribly anti-Semitic tweets"</a> (December 18, 2021)
          </li>
          <li>
            The Los Angeles Magazine
            article: <a
            href="https://www.lamag.com/citythinkblog/usc-in-free-speech-furor-over-student-who-tweeted-i-want-to-kill-every-motherf-cking-zionist/"
            target="_blank"
            rel="noopener noreferrer">"USC in Free Speech Furor Over Student Who Tweeted ‘I Want to Kill
            Every Motherf-cking Zionist’"</a> (December 17, 2021)
          </li>
          <li>
            The Jewish Journal
            article: <a
            href="https://jewishjournal.com/news/343405/usc-board-of-trustees-chair-the-antisemitic-behavior-we-are-witnessing-is-deeply-troubling/"
            target="_blank"
            rel="noopener noreferrer">"USC Board of Trustees Chair: “The Antisemitic Behavior We Are
            Witnessing Is Deeply Troubling”"</a> (December 16, 2021)
          </li>
          <li>
            The Jerusalem Post
            article: <a
            href="https://www.jpost.com/diaspora/antisemitism/toxic-atmosphere-of-hatred-usc-faculty-outraged-over-response-to-students-anti-zionist-tweets-688905?utm_source=ActiveCampaign"
            target="_blank"
            rel="noopener noreferrer">"‘Toxic atmosphere of hatred:’ USC faculty outraged over response to
            student’s anti-Zionist tweets"</a> (December 16, 2021)
          </li>
          <li>
            <a href="USC-Colleagues_12.15.21.pdf" target="_blank" rel="noopener noreferrer">
              Reply from President Folt</a> (December 15, 2021)
          </li>
          <li>
            <a
              href="https://boardoftrustees.usc.edu/statement-from-usc-board-of-trustees-chair-rick-j-caruso-december-14-2021/"
              target="_blank" rel="noopener noreferrer">
              Statement from USC Board of Trustees Chair Rick J. Caruso Regarding Antisemitic
              Behavior</a> (December 14, 2021)
          </li>
          <li>
            The Daily Mail
            article: <a
            href="https://www.dailymail.co.uk/news/article-10309757/Outrage-USCs-failure-punish-student-calling-death-mother-g-Zionist.html"
            target="_blank"
            rel="noopener noreferrer">"Outrage over USC’s failure to punish Palestinian Diversity, Equity
            and Inclusion student
            representative over anti-Semitic tweets she sent including her desire to ‘kill every
            mother*****g Zionist’"</a> (December 14, 2021)
          </li>
          <li>
            The Los Angeles Times
            article: <a
            href="https://www.latimes.com/california/story/2021-12-14/usc-faculty-open-letter-student-tweets"
            target="_blank"
            rel="noopener noreferrer">"‘Toxic atmosphere of hatred.’ USC faculty outraged over response to
            student’s
            tweets"</a> (December 14, 2021)
          </li>
          <li>
            <a href="https://twitter.com/PresidentFolt/status/1470565862247583744?s=20" target="_blank"
               rel="noopener noreferrer">
              Tweet from President Folt</a> (December 13, 2021)
          </li>
          <li>
            The Jewish News Syndicate
            article: <a
            href="https://www.jns.org/usc-president-responds-to-faculty-members-demanding-censure-of-students-hate-tweets/"
            target="_blank"
            rel="noopener noreferrer">"USC president responds to faculty members demanding
            censure of student’s hate tweets"</a> (December 8, 2021)
          </li>
          <li>
            The Cleveland Jewish News
            article: <a
            href="https://www.clevelandjewishnews.com/jns/usc-president-responds-to-faculty-members-demanding-censure-of-student-s-hate-tweets/article_db0dc181-1b2c-5973-8817-dc301ba1c36d.html"
            target="_blank"
            rel="noopener noreferrer">"USC president responds to faculty members
            demanding censure of student’s hate tweets"</a> (December 8, 2021)
          </li>
          <li>
            Jerry Coyne post: <a
            href="https://whyevolutionistrue.com/2021/12/08/what-should-a-university-do-when-a-rabid-anti-semitic-student-calls-for-the-killing-of-jews/"
            target="_blank" rel="noopener noreferrer">"What should a university do when a rabid anti-Semitic
            student calls for the killing of Jews?"</a> (December 8, 2021)
          </li>
          <li>
            The Jerusalem Post
            article: <a
            href="https://www.jpost.com/opinion/free-speech-isnt-always-free-on-university-campuses-opinion-688024"
            target="_blank" rel="noopener noreferrer">"Free speech isn't always free on university
            campuses - opinion."</a> (December 7, 2021)
          </li>
          <li>
            The Inside Higher Ed
            article: <a
            href="https://www.insidehighered.com/quicktakes/2021/12/07/southern-cal-president-answers-letter-anti-semitism"
            target="_blank" rel="noopener noreferrer">"Southern Cal President Answers Letter
            on Anti-Semitism."</a> (December 7, 2021)
          </li>
          <li>
            The Inside Higher Ed
            article: <a
            href="https://www.insidehighered.com/print/quicktakes/2021/12/06/southern-cal-faculty-lack-statement-creates-%E2%80%98toxic-atmosphere%E2%80%99"
            target="_blank" rel="noopener noreferrer">"Southern Cal Faculty: Lack of Statement
            Creates ‘Toxic Atmosphere’."</a> (December 6, 2021)
          </li>
          <li>
            The Jewish Journal
            article: <a
            href="https://jewishjournal.com/news/united-states/343067/usc-student-senator-under-fire-for-tweeting-i-want-to-kill-every-motherf-ing-zionist-other-alleged-antisemitic-tweets/"
            target="_blank" rel="noopener noreferrer">"USC Student
            Senator Under Fire for Tweeting “I Want to Kill Every Motherf—ing Zionist,”
            Other Alleged Antisemitic Tweets"</a> (December 6, 2021)
          </li>
          <li>
            The Algemeiner article: <a
            href="https://www.algemeiner.com/2021/12/06/usc-denies-being-soft-on-antisemitism-after-60-faculty-members-call-for-censure-of-graduate-students-tweets/"
            target="_blank" rel="noopener noreferrer">"USC Defends Response as 60 Faculty Members Call for
            ‘Rebuke’ Over
            Student’s Violently Antisemitic Tweets"</a> (December 6, 2021)
          </li>
          <li>
            <a href="https://twitter.com/yudapearl/status/1467162918206652416"
               target="_blank" rel="noopener noreferrer"> Judea Pearl tweeted
              about the Open Letter</a> (December 4, 2021)
          </li>
          <li>
            <a href="USC-Colleagues_12.3.21.pdf" target="_blank" rel="noopener noreferrer">
              Reply from President Folt and Provost Zukoski</a> (December 3, 2021)
          </li>
          <li>
            The Forward article: <a
            href="https://forward.com/news/479084/usc-faculty-demand-punishment-for-antisemitic-tweet/"
            target="_blank" rel="noopener noreferrer"> "A
            USC student tweeted ‘kill every motherf——ing Zionist.’
            Zionist faculty are unhappy with USC’s response."</a> (December 3, 2021)
          </li>
        </ul>
      </div>
    </Col>
  );
}

export default Reactions;
