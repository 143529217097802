import React from 'react';
import ShareLinks from '../ShareLinks/share';
import Signatures from "../Signatures/Signatures";
import { Col } from 'reactstrap';
import './petition.scss';

function Petition() {
  return (
    <Col>
      <div className="petition-wrapper">
        <h1>An Open Letter to the Leadership of USC</h1>
        <p className="date-wrapper">
          <span className="date">
            December 1, 2021
          </span>
        </p>
        <ShareLinks/>
        <p>
          Dear President Folt, Provost Zukoski, and Chairman Caruso,
        </p>
        <p>
          We, the undersigned faculty, wish to register our dismay about ongoing open expressions of anti-Semitism and
          Zionophobia on our campus that go unrebuked. The silence of our leadership on this matter is alienating,
          hurtful, and depressing. It amounts to tacit acceptance of a toxic atmosphere of hatred and hostility.
        </p>
        <p>
          In the Spring and Summer of 2021, USC student Yasmeen Mashayekh in the Viterbi School of Engineering, posted
          multiple statements on Twitter and other social media outlets including:
          <ul class={'expressions'}>
            <li>"I want to kill every motherf**cking Zionist,"</li>
            <li>"Death to Israel and its b**tch the U.S.,"</li>
            <li>"Israel has no history just a criminal record,"</li>
            <li>"yel3an el yahood [curse the Jews]."</li>
          </ul>
          Screenshots, dates, and links to these and other public expressions by Ms. Mashayekh are
          documented <a href="https://canarymission.org/individual/Yasmeen_Mashayekh" target="_blank" rel="noopener noreferrer">here</a> and <a href="https://www.stopantisemitism.org/antisemite-of-the-week-6/antisemite-of-the-week-yasmeen-mashayekh-thirst-to-kill" target="_blank" rel="noopener noreferrer">here</a> [<a href="#link1">1</a>, <a href="#link1">2</a>].
        </p>
        <p>
          We refrain from judging whether this student's speech is protected by the First Amendment and whether her
          behavior requires disciplinary action. Likewise, School officials should determine what to do about her
          appropriation of the title: "DEI Senator for the Engineering School." We leave it to Diversity, Equity, and
          Inclusion to determine whether such views fit into USC culture and its stated commitment to build a diverse
          and inclusive environment.
        </p>
        <p>
          We write this letter to call on USC leadership to publicly and explicitly rebuke Yasmeen Mashayekh for
          her offensive behavior and to distance USC from her hateful statements. Repeated threats of murder and harm
          based on race, religion, ethnicity, country of origin, gender, sexual orientation, etc. are not acceptable
          discourse, even in the context of heated debates on difficult political issues. The absence of explicit
          condemnation amounts to tacit endorsement. This is a slap in the face to USC students, staff, and faculty
          who are Jewish and to anyone who supports the right of the State of Israel to exist. A public statement is needed
          immediately given the continuing instances of anti-Semitism and Zionophobia on our campus.
        </p>
        <p>
          In August 2020, several of us signed
          an <a href="https://usc-faaz.org" target="_blank" rel="noopener noreferrer">Open Letter</a> concerning
          personal attacks against Rose Ritch, a USC undergraduate. She resigned as vice president of USC’s undergraduate
          student government because of intense abuse she endured due to her Jewish-Zionist identity [<a href="#link3">3</a>]. In August
          2021, <a href="https://usc-faaz-2021.org" target="_blank" rel="noopener noreferrer">another Open Letter</a> called
          your attention to an inflammatory and misleading online "Statement," that USC’s Department of Gender and Sexuality
          Studies publicly endorsed [<a href="#link4">4</a>]. This endorsement has not been rescinded.
          We await action.
        </p>
        <p>
          We ask you to voice a public, explicit and specific condemnation of Ms. Mashayekh’s statements. The campus
          community needs to know the name of the offender and the essence of the offence.  We urge you to condemn the
          hateful content of these statements and to reaffirm that such views are contrary to USC’s values. Most
          importantly, Jewish, Zionist, and Israeli students, as well as those who support the right of the State of Israel to
          exist need to hear from our leaders that they are welcome on our campus. Such a statement would not infringe on
          free speech or take sides in political dispute. It is a call for character and dignity. It is overdue.
        </p>
      </div>

      <Signatures/>
    </Col>
  );
}

export default Petition;