import React from 'react';
import { Col } from 'reactstrap';

function NewSupport() {
    return (
        <Col>
            <h2>Additional support for the Open Letter from USC community:</h2>

            <ul className="list-unstyled">
                <li>KEN ALEXANDER, Professor of Mathematics</li>
                <li>NISSIM BENBASSAT, DDS, USC Alumnus</li>
                <li>BETH FISHER PhD, PT, FAPTA, Professor of Clinical Physical Therapy; Director,
                    Neuroplasticity and Imaging Laboratory; Division of Biokinesiology and Physical Therapy</li>
                <li>RICHARD GREEN, DDS; Adjunct Professor of Clinical Dentistry, Herman Ostrow
                    School of Dentistry</li>
                <li>STUART J. HOFFMAN, D.M.D., M.S., F.I.C.D., F.A.C.D, Board Certified Specialist Exclusively
                    Orthodontics & Dentofacial Orthopedics, Adjunct Clinical Professor, Division of
                    Orthodontics, Ostrow School of Dentistry, Fellow, International College of
                    Dentists, Fellow, American College of Dentists; Fellow, Pierre
                    Fauchard Academy</li>
                <li>ROBERT ISRAEL, MD; Professor of Obstetrics and Gynecology, Keck School of Medicine</li>
                <li>DAVID E. JAMES, Professor Emeritus, School of Cinematic Arts, Department of
                    East Asian Studies</li>
                <li>RICHARD KAHN Emeritus Associate Professor, Herman Ostrow School of Dentistry;
                    Inductee to the Hall of Fame, Herman Ostrow School of Dentistry</li>
                <li>ALEXANDRA M. LEVINE, Distinguished Professor of Medicine (former); Medical
                    Director (former), USC/Norris Cancer Hospital</li>
                <li>MORRIS LEVY, Associate Professor of Political Science and International Relations</li>
                <li>STANLEY MALAMED, Dentist Anesthesiologist, Emeritus Professor of Dentistry Ostrow School of Dentistry</li>
                <li>FEDOR MALIKOV, Professor of Mathematics</li>
                <li>CHERYL RESNIK, PT, DPT, FNAP, FAPTA; Professor of Clinical Physical Therapy; Director,
                    Community Outreach; USC Division of Biokinesiology and Physical
                    Therapy at the Ostrow School of Dentistry</li>
                <li>ROBERT SALEHRABI, DDS,  Alumnus Ostrow School of Dentistry</li>
                <li>SUSAN SEGAL, Professor of Teaching, Department of English</li>
                <li>DANIEL S. SCHECHTER, D.D.S.; Assistant Clinical Professor, Herman Ostrow School
                    of Dentistry</li>
                <li>ANATOLY SEDLER, owner of Burbank dental lab on campus</li>
                <li>M. BEN SHALOM, D.D.S.; USC Alumnus</li>
                <li>ADRIAN SILBERMAN, DDS, Alumnus Ostrow School of Dentistry</li>
                <li>ARNOLD TIBER, Associate Professor, Herman Ostrow School of Dentistry</li>
                <li>CAROL WISE, Professor of International Relations</li>
            </ul>
        </Col>
    );
}

export default NewSupport;
